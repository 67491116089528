<template>
  <article class="survey" v-if="survey" v-bind:class="{embedded: isEmbedded}">
    <img v-if="survey && !sent" :src="survey.header_desktop.full.url" class="is-hidden-touch" alt />
    <img v-if="survey && !sent" :src="survey.header_mobile.full.url" class="is-hidden-desktop" alt />
    <div v-if="!sent && !isLoading" class="section">
      <header class="inpage-header">
        <div class="subtitle" v-html="survey.subtitle"></div>
      </header>

      <section
        @click="selectQuestion(index)"
        class="question"
        :class="{ 'is-active': (index == activeQuestionIndex), 'is-past': (index < activeQuestionIndex) }"
        :id="'q'+index"
        v-for="(question, index) in survey.questions"
        :key="question.key"
      >
        <header class="question-header">
          <h4 class="title is-5">{{question.title}}</h4>
        </header>
        <div class="question-body">
          <div
            class="field answer"
            v-for="answer in question.answers"
            :key="answer.value"
            :class="{ 'is-selected': answers[question.key] == answer.value}"
          >
            <input
              class="is-checkradio"
              :id="question.key + answer.value"
              type="radio"
              :name="question.key"
              @change="setAnswer(question.key, answer.value, index + 1)"
            />
            <label :for="question.key + answer.value">{{answer.title}}</label>
          </div>
        </div>
      </section>
    </div>

    <div v-if="isLoading" class="has-text-centered">
      <button class="button is-primary is-indicator is-outlined is-large is-loading"></button>
    </div>

    <div v-if="sent" class="thank-you content">
      <div class="hero">
        <div class="hero-body">

        <h2 class="ty-title">{{survey.ty_headline}}</h2>
        <p class="ty-subtitle">{{survey.ty_subline}}</p>
        <a
          class="button top is-outlined"
          @click="redeemOffer()"
          :class="{'is-loading': isLoading}"
        >{{survey.ty_cta_bottom}} &raquo;</a>
        <img class="ty-packshot" :src="survey.ty_image.large.url" alt />
        </div>
      </div>
      <div class="ty-content">
        <p class="ty-copy" v-html="survey.ty_copy"></p>
        <div class="buttons-wrap">
          <a
            class="button bottom"
            @click="redeemOffer()"
            :class="{'is-loading': isLoading}"
          >{{survey.ty_cta_bottom}} &raquo;</a>

          <a v-if="!isEmbedded && survey.ty_cta_option && survey.ty_link_option" 
            class="button bottom option"
            @click="redeemOffer(survey.ty_link_option)"
            :class="{'is-loading': isLoading}"
          >{{survey.ty_cta_option}} &raquo;</a>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable */
export default {
  name: "Survery",
  data: function () {
    return {
      answers: {},
      activeQuestionIndex: 0,
      sent: false,
      isLoading: false,
    };
  },
  computed: {
    survey: function () {
      return this.$store.state.survey;
    },
    surveyID: function () {
      return this.$store.state.urlParams.s;
    },
    offerID: function () {
      return this.$store.state.offerID;
    },
    tp: function () {
      return this.$store.state.tp;
    },
    urlAppendix: function () {
      return this.$store.state.urlAppendix;
    },
    isDisabled: function () {
      return (
        this.$data.activeQuestionIndex !=
        this.$store.state.survey.questions.length
      );
    },
    isEmbedded: function(){
      return this.$store.state.urlParams.embed ? true : false
    }
  },
  methods: {
    scrollToTop: function() {
          window.scrollTo(0,0);
    },
    setAnswer: function(q, a, i) {
      this.$data.activeQuestionIndex = i;
      this.$data.answers[q] = a;
      if (
        this.$data.activeQuestionIndex <
        this.$store.state.survey.questions.length
      ) {
        this.$scrollTo(document.getElementById("q" + i));
      }
      if (i == 1) {
        this.track("wiwosurvey_started");
      }
      if (
        this.$data.activeQuestionIndex ==
        this.$store.state.survey.questions.length
      ) {
        this.sendSurvey();
      }
    },
    selectQuestion: function (i) {
      this.$data.activeQuestionIndex = i;
    },
    sendSurvey: function () {
      this.isLoading = true;
      this.scrollToTop()
      let self = this;

      this.axios
        .post("https://api.lp.wiwo.de/surveys/" + this.surveyID, {
          answers: this.answers,
        })
        .then(function (response) {
          self.$data.sent = true;
          self.$data.isLoading = false;
          self.track("wiwosurvey_complete");
        })
        .catch((error) => {
          self.$data.sent = true;
          self.$data.isLoading = false;
          self.track("wiwosurvey_error");
        });
    },
    track: function (e) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: e,
      });
    },
    redeemOffer: function (link) {
      this.track("wiwosurvey_conversion");

      let url = "";
      if (link) {
        url = link;
      } else if (!this.tp) {
        // new url structure
        url = "https://assign.wiwo.de/" + this.urlAppendix;
      } else {
        url =
          "https://assign.wiwo.de?tp=" +
          this.tp +
          "&offerid=" +
          this.offerID +
          "&origin=sapmsd";
      }

      setTimeout(() => {
        window.location.replace(url);
      }, 250);
    },
  },
  components: {},
};
</script>

<style lang="scss" >

.survey {
  background: white;
}

.actions {
  .button.is-primary {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}

.thank-you-bullets {
  text-align: left;
  list-style: disc;
  li {
    text-align: left;
    font-size: 18px;
    margin-bottom: 0.75rem;
  }
}

.thank-you {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .hero {
    background-image: url('https://static.lp.wiwo.de/static-assets/conversion-bg.jpg');
    background-size: cover;
    background-position: bottom center;
    width: 100%;
  }
  .hero-body{
    padding-bottom: 3rem;
    text-align: center;
  }
  .ty-title {
    color: white;
    text-align: center;
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    text-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.2);
  }
  .ty-subtitle {
    color: white;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
    font-size: 1.25rem;
    text-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.1);
  }
  .ty-packshot {
    display: block;
    margin: 0 auto;
  }
  .ty-content{
    display: flex;
    flex-direction: column;
    margin-top: -2rem;
    margin-bottom: 5rem;
  }
  .ty-copy {
    font-size: 1.5rem;
    max-width: 620px;
    text-align: center;
  }
  .button.top {
    display: inline-block;
    margin: 1rem auto;
    background: none;
    border-radius: 6px;
    border-width: 1px;
    color: white;
    border-color: white;
    &:hover{
      border-color: white;
      background: white;
      color: #E31F25
    }
  }
  .button.bottom {
    background: black;
    border-radius: 6px;
    color: white;
    border-color: white;
    margin-top: .75rem;
    &:hover{
      background: black
    }
  }
  .buttons-wrap { 
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; 
  }
}

.question {
  opacity: 0.5;
  margin: 1rem 0;
  border: 1px solid #e6e3e3;

  .question-header {
    background: #efeded;
    padding: 15px 20px;
    line-height: 1.2;
    font-family: "Mote";
    font-weight: 500;
  }

  .question-body {
    padding: 20px;
  }
  &.is-active {
    opacity: 1;
    border-color: #10183f;

    .question-header {
      background-color: #10183f;
      .title {
        color: white;
      }
    }

    .question-body {
      display: block;
    }
  }
  &.is-past {
    cursor: pointer;
    opacity: 1;
  }

  .question-body {
    display: none;
  }
}
.button.is-outlined.is-primary.is-indicator {
  font-size: 4rem;
  border: none;
  &:after {
    border-color: transparent transparent #10183f #10183f !important;
    border-width: 3px;
  }
}
</style>
