import Vue from 'vue'
import App from './App_wiwo-surveys.vue'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import * as VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, {
  duration: 500,
  easing: "ease",
  offset: -150
})

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#wiwo-surveys')
