<template>
  <div id="surveys" class="container">
    <WiWoHeader v-if="!isEmbedded"/>
    <Survey/>
    <Quiz/>
    <WiWoFooter v-if="!isEmbedded"/>
  </div>
</template>

<script>
import WiWoHeader from './components/shared/WiWoHeader.vue'
import WiWoFooter from './components/shared/WiWoFooter.vue'
import Survey from './components/Survey.vue';
import Quiz from './components/Quiz.vue';

export default {
  name: 'surveys',
  components: {
    WiWoHeader,
    WiWoFooter,
    Survey,
    Quiz
  },
  created: function() {
    this.$store.dispatch("getUrlParams");
  },
  computed: {
    isEmbedded: function(){
      return this.$store.state.urlParams.embed ? true : false
    }
  }
}
</script>

<style lang="scss">
@import "~bulma/css/bulma.css";
@import "~bulma-checkradio/dist/css/bulma-checkradio.min.css";
@import "./assets/fonts/stylesheet.css";
@import "style/vars.scss";

.container {
  max-width: 1000px;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(35, 34, 34, 0.5);
}
</style>
