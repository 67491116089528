<template>
  <header class="wiwo-header">
    <svg
      version="1.1"
      id="Ebene_3"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 962.7 119.3"
      style="enable-background:new 0 0 962.7 119.3;"
      xml:space="preserve"
    >
      <g>
        <g>
          <rect x="7.4" y="64.3" class="st0" fill="#E31F25" width="47.8" height="47.8" />
          <rect x="7.4" y="8" class="st0" fill="#E31F25" width="47.8" height="47.8" />
        </g>
        <g>
          <path
            d="M924.3,67.9h11.2c0-3.9,0.2-10.8-0.4-15.2c-0.4-3.1-1.7-5.8-5-5.8c-2.9,0-4.6,1.5-5.2,4.4c-0.8,3.7-0.6,10.6-0.6,15.2
			V67.9z M924.3,85.1c0,10,5,13.5,12.5,13.5c4.6,0,12.1-2.9,15.8-7.3v15.2c-3.3,3.3-12.5,7.7-23.1,7.7c-13.5,0-23.9-9.1-25.1-25.6
			c-0.8-9.8-1.5-27.2,1-37.2c2.7-12.5,10.6-18.5,24.5-18.5c12.1,0,20.2,6,22.7,18.5c1.2,6.6,0.6,23.7,0.6,28.1v0.6h-28.9V85.1z"
          />
          <polygon
            points="718.3,112.8 700.4,112.8 687.8,55.6 675.3,112.8 657.2,112.8 640,8.6 663,8.6 663,9.3 671.6,71.4 683.4,8.6 
			699.8,8.6 711.5,71.4 720.4,8.6 736,8.6 		"
          />
          <path
            d="M760.1,114.4c-13.9,0-22.2-5.6-25.6-17.7c-1.7-6-2.3-14.3-2.3-22.9c0-8.3,0.4-16.6,2.3-22.7c3.5-12.1,12.1-18.3,25.6-18.3
			c12.9,0,22.2,6,25.4,18.3c1.5,5.6,1.9,13.5,1.9,22.7c0,9.1-0.4,17-1.9,22.9C782.2,108.8,773.7,114.4,760.1,114.4 M766.4,73.9
			c0-10.6-0.4-16.6-0.8-18.9c-0.8-5.2-2.3-6.9-5.8-6.9c-3.3,0-4.8,1.7-5.6,6.9c-0.4,2.3-0.8,8.3-0.8,18.9c0,10.6,0.4,16.6,0.8,18.9
			c0.8,5.2,2.3,6.9,5.8,6.9c3.3,0,4.8-1.7,5.6-6.9C766,90.5,766.4,84.5,766.4,73.9"
          />
          <path
            d="M839.1,38.4v24.9h-11.4L825,48.7c-1-0.6-2.9-0.8-3.5-0.8c-5.4,0-6.9,3.5-7.3,8.5c-0.4,7.5-0.6,22.9,0,30.1
			c0.6,8.3,3.9,12.1,10.6,12.1c5,0,10.4-3.1,15-8.5v16c-3.3,3.3-10.6,8.1-20.8,8.1c-14.4,0-23.7-9.4-25.2-23.7
			c-1-10.6-1-21.6,0-32.4c1.7-16.6,11-25.1,25.4-25.1C826,32.9,833.3,35.2,839.1,38.4"
          />
          <path
            d="M897.9,112.8h-21V53.9c0-3.7-2.1-5.8-5.2-5.8c-1.9,0-3.7,0.6-5.2,1.7v63h-21V10.9l21-5.2v32.2c4.2-3.1,8.9-5,14.8-5
			c11.6,0,16.6,7.9,16.6,20V112.8z"
          />
        </g>
        <g>
          <rect x="171.9" y="8.3" width="20.9" height="17.4" />
          <path
            d="M385.3,38.2v24.9h-11.4l-2.7-14.5c-1-0.6-2.9-0.8-3.5-0.8c-5.4,0-6.9,3.5-7.3,8.5c-0.4,7.5-0.6,22.8,0,30.1
			c0.6,8.3,3.9,12,10.6,12c5,0,10.4-3.1,14.9-8.5v16c-3.3,3.3-10.6,8.1-20.8,8.1c-14.3,0-23.6-9.3-25.1-23.6c-1-10.6-1-22.4,0-33.2
			c1.7-16.6,11-24.3,25.3-24.3C372.3,32.8,379.5,35.1,385.3,38.2"
          />
          <path
            d="M275.4,98.1c2.9,0,6.2-1.7,8.9-4.1v13.9v0.2c-3.9,2.9-10.4,5.4-17.2,5.4c-10,0-18-5.8-18-20.5V36.1l19.1-16.8h1.9v15.1
			h12.4v13.3H270v45C270,97.1,272.5,98.1,275.4,98.1"
          />
          <path
            d="M531.3,112.4h-20.9V47.7h-7.1V34.5h7.1v-4.8c0-14.3,7.7-22,20.9-22c4.8,0,10.2,0.8,14.9,2.7v13.7c-3.1-1-6.2-1.9-8.5-1.9
			c-5.4,0-7.5,2.5-7.5,7.5v4.8H544v13.3h-12.7V112.4z"
          />
          <path
            d="M221.8,112.4h-20.9V54.2c0-7.5-0.6-13.9-1.7-19.7H216l2.3,7.7c5.4-6.6,10.6-8.9,16.8-8.9c2.9,0,6,0.8,8.7,2.3v28.6h-11.4
			l-1.9-12.4c-3.9-1-8.7,0-8.7,6.8V112.4z"
          />
          <rect x="171.9" y="34.5" width="20.9" height="78" />
          <path
            d="M466.6,114.5c-5.4,0-9.8-2.1-13.3-6.6c-2.3-3.1-3.9-8.3-3.9-16c0-7.9,0.4-13.1,3.5-17.6c3.9-6,11.6-9.3,26.8-7.9V56.2
			c0-6.6-3.1-9.1-8.9-9.1c-1.5,0-3.3,0.4-4.6,1l-1.9,12.7h-11.6V39.2c5.6-3.7,14.5-6.4,22-6.4c15.3,0,24.9,6.8,24.9,22.8v36.9
			c0,5.8,0.6,12.2,2.3,19.1v0.8h-17l-2.3-6.2C479.1,111.4,472.6,114.5,466.6,114.5 M468.7,88c0,10.4,2.1,11.4,5.2,11.4
			c2.1,0,5.8-2.1,5.8-6.4V77.8c-1.7-0.4-3.1-0.4-4.1-0.4C470.8,77.4,468.7,79.5,468.7,88"
          />
          <polygon
            points="150.4,112.4 132.6,112.4 119.9,55.2 107.5,112.4 89.4,112.4 72.2,8.3 95.2,8.3 95.2,8.9 103.7,71 115.6,8.3 
			132,8.3 143.6,71 152.5,8.3 168,8.3 		"
          />
          <path
            d="M607.2,113.3c-5.8,0-13.9-1.9-19.3-5.6v-22h11L601,99c1.7,0.4,3.7,0.6,4.8,0.6c5.6,0,9.1-3.3,9.1-7.3c0-6-6-8.5-14.5-14.3
			c-8.1-5.4-13.5-12.9-13.5-22.6c0-13.5,9.5-22.6,24.3-22.6c7.5,0,14.1,2.3,19.1,5v21.4h-11.2l-1.7-12c-5.6-1.5-11.6,0-11.6,6.4
			c0,5,6.4,8.3,13.1,12.4c8.5,5.4,15.1,12.2,15.1,24.7C634,102.5,623.8,113.3,607.2,113.3"
          />
          <path
            d="M308.5,113.3c-5.8,0-13.9-1.9-19.3-5.6v-22h11l2.1,13.3c1.7,0.4,3.7,0.6,4.8,0.6c5.6,0,9.1-3.3,9.1-7.3
			c0-6-6-8.5-14.5-14.3c-8.1-5.4-13.5-12.9-13.5-22.6c0-13.5,9.5-22.6,24.3-22.6c7.5,0,14.1,2.3,19.1,5v21.4h-11.2l-1.7-12
			c-5.6-1.5-11.6,0-11.6,6.4c0,5,6.4,8.3,13.1,12.4c8.5,5.4,15.1,12.2,15.1,24.7C335.2,102.5,325.1,113.3,308.5,113.3"
          />
          <path
            d="M443.9,112.4h-20.9V53.7c0-3.7-2.1-5.8-5.2-5.8c-1.9,0-3.7,0.6-5.2,1.7v62.8h-20.9V10.8l20.9-5.2v32.1
			c4.1-3.1,8.9-5,14.7-5c11.6,0,16.6,7.9,16.6,19.9V112.4z"
          />
          <path
            d="M574.5,98.1c2.9,0,6.2-1.7,8.9-4.1v13.9v0.2c-3.9,2.9-10.4,5.4-17.2,5.4c-10,0-18-5.8-18-20.5V36.1l19.1-16.8h1.9v15.1
			h12.4v13.3h-12.4v45C569.2,97.1,571.6,98.1,574.5,98.1"
          />
        </g>
      </g>
    </svg>
  </header>
</template>

<script>
export default {
  name: "wiwo-header",
};
</script>

<style lang="scss" >
.wiwo-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  border-bottom: 1px solid #e6e3e3;

  svg {
    display: flex;
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .wiwo-header {
    padding: 20px;
    .logo {
      max-width: 200px;
    }
  }
}
</style>