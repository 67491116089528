/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    survey: false,
    offerID: false, // remove in with next campaign
    tp: false,  // remove in with next campaign
    passThroughURLParams: ['tp', 'offerid', 'origin', 'q', 's', 's_id','service','lang','campaignid','salesorganisationid','fingerprintsecretno','fingerprint','fbclid','ptid','_ptid','trackingId'],
    urlAppendix: "",
    urlParams: {},
    promocode: [],
    promocodeConfig: false,
    autovalidate: false,
    quiz: false,
    quizQuestionIndex: -1,
    config: false,
  },
  mutations: {
    setUrlParams: (state, params) => {
      state.urlParams = params
    },
    setSurvey: (state, survey) => {
      state.survey = survey
    },
    setQuiz: (state, quiz) => {
      state.quiz = quiz
    },
    setQuizQuestionIndex: (state, index) => {
      state.quizQuestionIndex = index
    },
    setAppendix: (state, appendix) => {
      state.urlAppendix = appendix
    },
    setOfferId: (state, id) => {
      state.offerID = id;
    },
    setTp: (state, tp) => {
      state.tp = tp;
    },
    setPromoCode: (state, codes) => {
      state.promocode = codes
      var _code =  state.promocode.join("")
      if(window.location.search.indexOf("v=1") != -1) {
        if( _code.length == 9) {
          Vue.axios.get(process.env.VUE_APP_API_ENDPOINT + "/promocodes/" + _code + "/validate" ).then(function (response) {
            if(response.data.promocode) {
              if(window.location.search.indexOf("r=1") != -1) {
                state.autovalidate = true
              } 
              state.promocodeConfig = response.data.promocode
            }
          })
        } 
      }
    },
    setConfig: (state, config) => {
      state.config = config
    }
  },
  actions: {
    getUrlParams: function (context) {
      let params = {}
      const vars = window.location.search.substring(1).split('&');
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      context.commit("setUrlParams", params);
      
      const cookieHelper = {
        set: function (name, value, expiry) {
          expiry = (expiry) ? expiry : 30
          var exp = new Date(Date.parse(new Date()) + (expiry * 24 * 60 * 60 * 1000));
          var domain = window.location.hostname.replace(/www./i, '');
          document.cookie = name + '=' + value + '; path=/; expires=' + exp + '; domain=' + domain;
        },
        get: function (cookie_name) {
          var regex = new RegExp('(^|;\\s)' + cookie_name + '=([^;]+)', 'i');
          return (document.cookie.match(regex) || [])[2];
        },
        delete: function (name) {
          var domain = window.location.hostname.replace(/www./i, '');
          document.cookie = name + '=; domain=' + domain + '; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
      }
      
      if (params.hasOwnProperty("fbclid")) {
        cookieHelper.set("__fbc", params.fbclid, 1);
      } else if (typeof cookieHelper.get("__fbc") === "string") {
        params.fbclid = cookieHelper.get("__fbc");
        cookieHelper.delete("__fbc")
      }
      
      let appendix = "";
      this.state.passThroughURLParams.forEach(ptp => {
        if(params.hasOwnProperty(ptp)) {
          let pre = (appendix === "") ? "?" : "&"
          appendix +=  pre + ptp  + "=" + params[ptp]
        }
      });
      context.commit("setAppendix", appendix);
      
      if (params.hasOwnProperty("s")) {
        Vue.axios.get(process.env.VUE_APP_API_ENDPOINT + "/surveys/" + params.s).then(function (response) {
          if (response.status == 200) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "wiwosurvey_loaded"
            });
            context.commit("setSurvey", response.data)
            
            //remove with next campaign
            if (params.hasOwnProperty("o")) {
              if(params.o == "d") {
                context.commit("setOfferId", response.data.offerid_display)
                context.commit("setTp", "W-5AQY00_WWDGP_Neujahrsumfrage_display_20200108_lp_link_V0")
              } else if (params.o == "e") {
                context.commit("setOfferId", response.data.offerid_email)
                context.commit("setTp", "W-5APY00_WWDGP_Neujahrsumfrage_email_20200108_lp_link_V0")
              } else if (params.o == "s") { 
                context.commit("setOfferId", response.data.offerid_social)
                context.commit("setTp", "W-5ANY00_WWDGP_Neujahrsumfrage_social_20200108_lp_link_V0")
              }
            }
          }
        })
      }
      
      if (params.hasOwnProperty("code")) {
        context.commit("setPromoCode", params.code.split("-"))
      }
      
      if (params.hasOwnProperty("q")) {
        Vue.axios.get(process.env.VUE_APP_API_ENDPOINT + "/quiz/" + params.q).then(function (response) {
          if (response.status == 200) {
            response.data.questions.forEach(q => {
              q.answers.forEach(a => {
                a.resolved = false
                a.selected = false
              })
            })
            
            if(window.location.href.indexOf("skip=1") != -1) {
              context.commit("setQuizQuestionIndex", 0)
            }
            
            context.commit("setQuiz", response.data)
          }
        })
      }

    },
    getConfig: function (context) {
      Vue.axios.get(process.env.VUE_APP_API_ENDPOINT + "/config").then(function (response) {
        context.commit("setConfig",response.data)
      })
    }
  },
  modules: {
  }
})
