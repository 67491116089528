<template>
  <footer class="wiwo-footer">
    <a target="_blank" href="https://handelsblattgroup.com/agb/">Nutzungshinweise</a>
    <a target="_blank" href="https://www.wiwo.de/impressum/">Impressum</a>
    <a href="#" onclick="window._sp_.loadPrivacyManagerModal(194013); return false;">Datenschutzeinstellungen</a>
  </footer>
</template>

<script>
export default {
  name: "wiwo-footer",
};
</script>

<style lang="scss" >
.wiwo-footer {
  background-color: #232222;
  color: #767070;
  outline: #232222 solid 1px;
  font-size: 0.875rem;
  line-height: 1.07143;
  letter-spacing: 0.03em;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  a {
    color: #767070;
    text-decoration: none;
  }
}
.wiwo-footer a:not(:first-child)::before {
  content: "\2022";
  margin-left: 5px;
  margin-right: 5px;
}
.wiwo-footer a:hover {
  text-decoration: underline;
}

</style>