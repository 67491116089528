<template>
  <article class="quiz" v-if="quiz" v-bind:class="{embedded: isEmbedded}">
    <img v-if="quiz && currentQuestionIndex == -1" :src="quiz.header_desktop.full.url" class="is-hidden-touch" />
    <img v-if="quiz && currentQuestionIndex == -1" :src="quiz.header_mobile.full.url" class="is-hidden-desktop" />

    <div class="quiz-content">

      <div class="quiz-start has-text-centered" v-if="currentQuestionIndex == -1">
        <h1 class="title is-3 quiz-title">{{quiz.title}}</h1>
        <p class="subtitle is-5 quiz-subtitle">{{quiz.subtitle}}</p>
        <div class="button is-primary" @click="nextQuestion()">Quiz starten &raquo;</div>
      </div>
      <div class="quiz-running" v-if="currentQuestionIndex > -1 && currentQuestionIndex != quiz.questions.length">
        <div class="quiz-questions" v-if="currentQuestionIndex > -1">
          <div class="columns score is-mobile" v-if="currentQuestionIndex < quiz.questions.length">
            <div class="column">
              <p>{{currentQuestionIndex + 1}}/{{quiz.questions.length}}</p>
            </div>
            <div class="column has-text-right">
              <p>
                {{score}} Punkt<span v-if="score > 1  || score == 0">e</span>
              </p>
            </div>
          </div>
          <div class="columns" v-if="currentQuestionIndex < quiz.questions.length">
            <div class="column indicators">
              <div class="indicator" v-for="(q, i) in quiz.questions"                   v-bind:key="q.question"  :class="{current: currentQuestionIndex == i}">
                <span
                  class="dot"
                  :class="{correct : isCorrectlyAnswered(i), wrong : isWronglyAnswered(i)}"
                  :data-index="i"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="quiz-current-question columns" v-if="currentQuestion">
          <div class="column has-text-centered question-content">
            <p class="heading">Frage {{currentQuestionIndex + 1 }}</p>
            <p class="question-title">{{currentQuestion.question}}</p>
            <div class="answers">
              <button
                v-for="answer in currentQuestion.answers"
                v-bind:key="answer.answer"
                class="button"
                :class="{resolved: answer.resolved, correct: answer.correct, wrong: !answer.correct, selected: answer.selected}"
                @click="setUserAnswer(answer.answer)"
              >{{answer.answer}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="quiz-end" v-if="currentQuestionIndex == this.quiz.questions.length">
        <div class="thank-you content">
      <div class="hero">
        <div class="hero-body">
          <h2 class="title has-text-centered ty-title">
            <span v-if="!isEmbedded">{{quiz.ty_headline}}</span>
            <span v-if="score >= quiz.questions.length / 2 && isEmbedded">{{quiz.ty_headline_good}}</span>
            <span v-if="score < quiz.questions.length / 2 && isEmbedded">{{quiz.ty_headline_bad}}</span>
            – {{score}} Punkt<span v-if="score > 1 || score == 0">e</span></h2>
          <p v-if="score >= quiz.questions.length / 2" class="thank-you-subtitle">{{quiz.ty_result_good}}</p>
          <p v-if="score < quiz.questions.length / 2" class="thank-you-subtitle">{{quiz.ty_result_bad}}</p>
          <a
            class="button top is-outlined"
            @click="redeemOffer()"
            :class="{'is-loading': isLoading}"
          >{{quiz.ty_cta_bottom}} &raquo;</a>
          <img class="ty-packshot" :src="quiz.ty_image.large.url" alt />
        </div>
      </div>
      <div class="ty-content">
        <p v-if="!isEmbedded" class="ty-copy" v-html="quiz.ty_copy"></p>
        <div class="buttons-wrap">
          <a v-if="!isEmbedded" 
            class="button bottom"
            @click="redeemOffer()"
            :class="{'is-loading': isLoading}"
          >{{quiz.ty_cta_bottom}} &raquo;</a>

          <a v-if="!isEmbedded && quiz.ty_cta_option && quiz.ty_link_option" 
            class="button bottom option"
            @click="redeemOffer(quiz.ty_link_option)"
            :class="{'is-loading': isLoading}"
          >{{quiz.ty_cta_option}} &raquo;</a>
       
        </div>
      </div>
       
          <a
            class="button is-light bottom"
            v-if="!showResults && !isEmbedded"
            @click="toggleResults()"
          >Ergebnisse anzeigen &raquo;</a>
        </div>
        <div class="results" v-if="showResults">
          <hr />
          <h2
            class="title is-4 has-text-centered"
          >{{score}} von {{quiz.questions.length}} Fragen richtig beantwortet</h2>
          <div
            class="card"
            v-for="(q,i) in quiz.questions"
            :key="q.question"
            :class="{correct : isCorrectlyAnswered(i), wrong : isWronglyAnswered(i)}"
          >
            <div class="card-content">
              <h4 class="heading">
                <span class="dot"></span>
                Frage {{i+1}}
              </h4>
              <h6 class="title is-6">{{q.question}}</h6>
              <p>
                Ihre Antwort:
                <span class="useranswer" v-for="a in q.answers" :key="a.answer">
                  <span v-if="a.selected">{{ a.answer }}</span>
                </span>
              </p>
              <p>
                Richtige Antwort:
                <span class="quizanswer" v-for="a in q.answers" :key="a.answer">
                  <span v-if="a.correct">{{ a.answer }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>


      </div>
    </div>
  </article>
</template>

<script>
/* eslint-disable */
export default {
  name: "Quiz",
  data: function() {
    return {
      sent: false,
      showResults: false,
      isLoading: false
    };
  },
  created: function() {
    this.track("start");
    // this.fake();
  },
  computed: {
    currentQuestionIndex: function(){
      return this.$store.state.quizQuestionIndex
    },
    quiz: function() {      
      return this.$store.state.quiz
    },
    currentQuestion: function() {
      return this.quiz.questions[this.currentQuestionIndex];
    },
    urlAppendix: function() {
      return this.$store.state.urlAppendix;
    },
    score: function() {
      let score = 0;
      this.quiz.questions.forEach(q => {
        const correctAnswer = q.answers.filter(a => a.correct);
        if (correctAnswer[0].selected) {
          score++;
        }
      });
      return score;
    },
    isEmbedded: function(){
      return this.$store.state.urlParams.embed ? true : false
    }
  },
  methods: {
    fake: function() {
      var self = this;
      this.currentQuestionIndex = 4
      setTimeout(function() {
        self.quiz.questions.forEach(q => {
          q.answers[0].selected = true;
        });
      }, 500);
    },
    nextQuestion: function() {
      let index = this.currentQuestionIndex + 1;
      if (index < this.quiz.questions.length) {
        this.track(index);
      } else {
        this.track("result");
      }
      this.$store.commit("setQuizQuestionIndex",index)
      // this.currentQuestionIndex = index;
    },
    setUserAnswer: function(answer) {
      this.currentQuestion.answers.filter(
        a => a.answer == answer
      )[0].selected = true;
      this.animateAnswerForQuestion();
    },
    animateAnswerForQuestion: function() {
      var self = this;

      if (
        this.currentQuestion.answers.filter(a => a.resolved == false).length ==
        0
      ) {
        setTimeout(function() {
          self.nextQuestion();
        }, 1000);
        return;
      }

      var unselectedWrongAnswers = this.currentQuestion.answers.filter(a => {
        return a.selected == false && a.correct == false && a.resolved == false;
      });
      if (unselectedWrongAnswers.length > 0) {
        const answer =
          unselectedWrongAnswers[
            Math.floor(Math.random() * unselectedWrongAnswers.length)
          ];
        const answerIndex = this.currentQuestion.answers.indexOf(answer);
        this.currentQuestion.answers[answerIndex].resolved = true;
      } else {
        var selectedWrongAnswer = this.currentQuestion.answers.filter(a => {
          return (
            a.selected == true && a.correct == false && a.resolved == false
          );
        });
        if (selectedWrongAnswer.length > 0) {
          const answerIndex = this.currentQuestion.answers.indexOf(
            selectedWrongAnswer[0]
          );
          this.currentQuestion.answers[answerIndex].resolved = true;
        } else {
          var selectedRightAnswer = this.currentQuestion.answers.filter(a => {
            return a.resolved == false;
          });
          if (selectedRightAnswer.length > 0) {
            const answerIndex = this.currentQuestion.answers.indexOf(
              selectedRightAnswer[0]
            );
            this.currentQuestion.answers[answerIndex].resolved = true;
          }
        }
      }
      setTimeout(function() {
        self.animateAnswerForQuestion();
      }, 300);
    },
    isCorrectlyAnswered: function(i) {
      const selectedAnswer = this.quiz.questions[i].answers.filter(
        a => a.selected
      );
      if (selectedAnswer.length > 0) {
        return selectedAnswer[0].correct;
      }
      return false;
    },
    isWronglyAnswered: function(i) {
      const selectedAnswer = this.quiz.questions[i].answers.filter(
        a => a.selected
      );
      if (selectedAnswer.length > 0) {
        return !selectedAnswer[0].correct;
      }
      return false;
    },
    redeemOffer: function(link) {
      this.isLoading = true;
      this.track("conversion");
      let url = "";
      if (link) {
        url = link
      } else if(this.urlAppendix.indexOf("tp=") == -1 || this.isEmbedded) {
          url = "https://assign.wiwo.de/" + this.quiz.trackingAppendix; 
      } else {
          url = "https://assign.wiwo.de/" + this.urlAppendix;
      }
      setTimeout(() => {
        if (this.isEmbedded){
          window.open(
url,  '_blank' // <- This is what makes it open in a new window.
);

        } else {
          window.location.replace(url);
        }
      }, 250);
    },
    toggleResults: function() {
      this.showResults = true;
    },
    track: function(page) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "virtualPageView",
        quiz: this.$store.state.urlParams.q,
        vPage: "/quiz/" + page
      });
    }
  }
};
</script>

<style lang="scss" >
$green: rgb(32, 172, 87);
$red: rgb(217, 0, 29);

.quiz{
  &.embedded{
    border: 1px solid #e6e3e3;
    height: 100vh;
    .quiz-title{
      font-size: 1.5rem;
      line-height: 1.2;
      z-index: 3;
      font-family: Mote,sans-serif;
      font-weight: 700;
      color:#232222
    }
    .quiz-subtitle{
      font-size: 1rem;
      line-height: 1.3;
      color: #232222
    }
  }
}

.quiz-content {
  // padding: 1rem 1rem;
  .score {
    margin-bottom: 0;
    .column {
      padding-bottom: 0;
    }
  }
}

.quiz-end {

  .title
  .button {
    margin: 0.5rem;
  }
  .button.is-light{
    background-color: white !important;
    color: black !important
  } 


}

.indicators {
  position: relative;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .indicator{
    display: flex;
    margin:  0 5px 0 0;
    transition: all 1s ease-in-out;
    &.current {
      flex: 1;
    }
    &:last-child{
      margin-right: 0px;
    }
  }

  .dot {
    display: block;
    width: 0.7rem;
    height: 0.7rem;
    background: #ccc;
    border-radius: 5rem;
    &.wrong {
      background: $red;
    }
    &.correct {
      background: $green;
    }

  }
}
.question-content {
  margin: 3rem auto;
}
.question-title {
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  font-style: italic;
  max-width: 500px;
}
.answers {
  .button {
    display: block;
    margin: 10px auto;
    width: 100%;
    max-width: 500px;
    white-space: normal;
    height: auto;
    background: #f2f2f2;
    transition: all 0.15s ease-in-out;
    border-width: 1px;

    &:focus {
      outline: 0 !important;
      box-shadow: none;
      border-color: #232222;
    }
    &:active,
    &:hover {
      border-color: #232222;
    }

    &.resolved {
      opacity: 0.5;
      background: white;
      border-color: white;

      &.correct {
        background: $green;
        border-color: $green;
        color: white;
        opacity: 1;
      }

      &.selected {
        opacity: 1;

        &.wrong {
          color: $red;
          border-color: white;
          text-decoration: line-through;
        }
        &.correct {
          animation: pulse 0.5s 1;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.card {
  margin-bottom: 1rem;
  border: 1px solid #dbdbdb;

  .dot {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 2rem;
    margin-right: 0.15rem;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  &.wrong {
    .dot {
      background: $red;
    }
    .useranswer {
      color: $red;
    }
  }
  &.correct {
    .dot {
      background: $green;
    }
    .useranswer {
      color: $green;
    }
  }
  .title {
    margin-bottom: 0.5rem;
  }
  .quizanswer {
    color: $green;
  }
}

li {
  &.correct {
    color: green;
  }
  &.wrong {
    color: red;
  }
  &.answer {
    background: #ccc;
  }
}
.thank-you-subtitle{
  color: white;
  font-size: 1.4rem;
  max-width: 80%;
  margin: 0 auto;
}


.quiz.embedded {
  .quiz-start{
    padding: 2rem;
  }
  .ty-title{
    font-size: 1.2rem;
        margin: 0 0 0.5rem 0;
        line-height: 1.2rem;
  }
  .thank-you-subtitle{
    font-size: 1rem;
    max-width: 100%;;
    line-height: 1.2rem;
  }
  .thank-you{
    .hero-body{
      padding-top: 1rem;
    }
  
    .button.top{
      margin: 0;
    }
  }
  
}
.quiz-start{
  padding: 4rem 3rem;
}
.quiz-running{
  padding: 1rem
}
</style>
